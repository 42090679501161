import { NoDataProps } from './props';
import notFoundImg from '../../../assets/images/not-found.png';

const NoData = ({ errorOnInitialConfig, children }: NoDataProps) => {
  return (
    <>
      {errorOnInitialConfig ? (
        <div className="not-found-container column-vh-center">
          <img src={notFoundImg} alt="not-found" />
          <p>{errorOnInitialConfig}</p>
        </div>
      ) : (
        { ...children }
      )}
    </>
  );
};

export default NoData;
