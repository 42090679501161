import { UploadFileSendBtnProps } from './props';
import { showToast } from '../../../../../helpers/toast';
import { useAvatarContext } from '../../../../../context/Avatar/AvatarContext';
import { toMessageFormat } from '../../../../../models/chat';
import { useChatContext } from '../../../../../context/Chat/Chat';

const UploadFileSendBtn = ({ message }: UploadFileSendBtnProps) => {
  const { addNewMessage, editMessageById, uploadFilePartial } =
    useAvatarContext();
  const { setLoading, fileDescription, setFileDescription } = useChatContext();

  const onClick = async () => {
    setLoading(true);
    showToast('Subiendo archivo...', 'info');
    const res = await uploadFilePartial(message.file, fileDescription);
    setLoading(false);
    if (res) {
      showToast('Archivo enviado correctamente.', 'success');
      setFileDescription('');
      // edit send file message
      editMessageById(message.id, {
        respuesta_usuario: 'Archivo enviado correctamente.',
        file: undefined,
      });
      const gptResponse = toMessageFormat(res);
      addNewMessage(gptResponse, 'bot', 'GPT-CEU');
    } else {
      showToast(
        res?.respuesta_usuario ??
          'No se pudo subir el fichero. Inténtalo de nuevo.',
        'error'
      );
    }
  };

  return (
    <button className="file-btn file-accept-btn light-shadow" onClick={onClick}>
      Enviar
    </button>
  );
};

export default UploadFileSendBtn;
