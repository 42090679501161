import React, { useState, useEffect, useRef } from 'react';
import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk';
import ReactPlayer from 'react-player';

import Chat from '../components/Chat';
import LogoutBtn from '../components/LogoutBtn';
import '../../css/avatar.css';
import useMsalHook from '../../auth/useMsalHook';

import { AvatarConfiguration, AvatarResponse } from '../../models/avatar';
import { Message, toMessageFormat } from '../../models/chat';
import { PeerConnection } from '../../models/peerConnection';

import micNormalImg from '../../assets/images/microfono.png';
import micListeningImg from '../../assets/images/microfono-escuchando.png';
import micForbiddenImg from '../../assets/images/microfono-prohibido.png';
import { VOLUMEN_OFF, VOLUMEN_ON, avatarType, usuario } from '../../constants';
import {
  getInitialConfig,
  getGptData,
  getVariableData,
  getAzureVoiceToken,
  uploadFile,
  postOnIceCandidate,
  postCreateSession,
  postSendAnswer,
  postInitStreaming,
} from '../../services/api';
import { AvatarContext } from '../../context/Avatar/AvatarContext';
import { AvatarContextModel } from '../../context/Avatar/props';
import { showToast } from '../../helpers/toast';
import NoData from '../components/NoData';
import { AzureTalk } from '../../models/azure';

var loadingIce: boolean = false; // used to avoid multiple request from d-id "/ice"
var isReconnecting: boolean = false;
var isWaitingResponse: boolean = false;
var isMicOpened: boolean = false; // controla si el micro está abierto/escuchando(true) o cerrado/pausado/inexistente(false)

const AvatarApp = () => {
  const { user, userToken, logout } = useMsalHook();

  // useState
  const [displayText, setDisplayText] = useState<string>('Pulsa para hablar');
  const [chatInput, setChatInput] = useState<string>('');
  const [isChatSent, setIsChatSent] = useState<boolean>(false);
  const [isMicEnabled, setIsMicEnabled] = useState<boolean | undefined>(
    undefined
  ); // controla si el micro ha sido creado(true)/pausado(false)/inexistente(undefined)
  const [streamingState, setStreamingState] = useState<string | undefined>(''); // aqui el undefined es solo por tipado, nunca será
  const [prevStreamingState, setPrevStreamingState] = useState<
    string | undefined
  >(''); // aqui el undefined es solo por tipado, nunca será
  const [micImg, setMicImg] = useState<string>(micNormalImg);
  const [showStreaming, setShowStreaming] = useState<boolean>(false); //deberia ser false, para ver el video de vimeo
  const [messages, setMessages] = useState<Message[]>([]);
  const [isBotThinking, setIsBotThinking] = useState<boolean>(false);
  const [initialConfig, setInitialConfig] = useState<
    AvatarConfiguration | undefined
  >(undefined);
  const [errorOnInitialConfig, setErrorOnInitialConfig] = useState<string>('');

  // useRef
  const talkVideoRef = useRef<HTMLVideoElement>(null);
  const peerConnectionRef = useRef<PeerConnection[]>([
    { id: 0, connection: null, active: false },
    { id: 1, connection: null, active: false },
    { id: 2, connection: null, active: false },
    { id: 3, connection: null, active: false },
  ]);
  const userRefIndexConnection = useRef<number>(0);
  let indexConnection = userRefIndexConnection.current;
  const streamIdRef = useRef<string | null>(null);
  const sessionIdRef = useRef<string | null>(null);
  const speechRecognizerRef = useRef<speechsdk.SpeechRecognizer | null>(null);

  // const
  const streamingMode: boolean = initialConfig?.avatarMode === 'streaming';
  // const chatOnlyMode: boolean = initialConfig?.avatarMode === 'chat_only';
  const voiceOnlyMode: boolean = initialConfig?.avatarMode === 'chat_voice';
  const voiceSpeechMode: boolean =
    initialConfig?.avatarMode === 'chat_voice_speech';
  const userName = user?.name || usuario || 'Me';

  useEffect(() => {
    if (avatarType) {
      (async () => {
        showToast('Cargando configuración inicial...', 'info');
        const initialConfig = await getInitialConfig();
        if (initialConfig) {
          showToast(`Configuración de "${avatarType}" cargada.`, 'success');
          setInitialConfig(initialConfig);
        } else {
          setErrorOnInitialConfig(
            `No se pudo cargar o no existe el avatar "${avatarType}".`
          );
          showToast(`No se pudo cargar "${avatarType}".`, 'error');
        }
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (userToken && user && initialConfig) {
        await getGptData('--reset', userToken, user);
        if (!streamingMode) {
          const initialText = 'Hola';
          talk(initialText, true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialConfig, userToken, user]);

  const initializeOrResumeMic = (speechRecognizer: any) => {
    if (speechRecognizer) {
      speechRecognizer.startContinuousRecognitionAsync(
        onStartContinuousRecognition(speechRecognizer),
        onStartContinuousRecognitionError
      );
    }
  };

  const pauseMic = () => {
    if (speechRecognizerRef.current) {
      isMicOpened = false;
      speechRecognizerRef.current.stopContinuousRecognitionAsync();
    }
  };

  const destroyMic = (defaultState: boolean = false) => {
    if (speechRecognizerRef.current) {
      isMicOpened = false;
      speechRecognizerRef.current.stopContinuousRecognitionAsync();
      speechRecognizerRef.current = null;
      if (defaultState) {
        setTimeout(() => {
          setIsMicEnabled(undefined);
        }, 200);
      }
    }
  };

  const changeVisualMode = (mode: string) => {
    const resetStates = () => {
      isReconnecting = false;
      isMicOpened = false;
      setDisplayText('Pulsa para hablar');
      setIsChatSent(false);
      setIsMicEnabled(undefined);
    };
    switch (mode) {
      case 'finish':
        if (!isMicOpened) {
          initializeOrResumeMic(speechRecognizerRef.current);
        }
        if (!streamingMode) {
          setIsChatSent(false);
        }
        break;
      case 'reset':
        resetStates();
        break;
      case 'failed':
        resetStates();
        setDisplayText(
          'No se pudo conectar con los servicios de streaming. Pulsa para reintentarlo'
        );
        break;
      case 'open-mic':
        if (!isMicOpened) {
          initializeOrResumeMic(speechRecognizerRef.current);
        }
        break;
      case 'close-mic':
        if (isMicOpened) {
          pauseMic();
        }
        break;
      default:
        break;
    }
  };

  const onStartContinuousRecognition = (
    speechRecognizer: speechsdk.SpeechRecognizer | null
  ) => {
    window.focus();

    isMicOpened = true;
    if (!isReconnecting) {
      setIsMicEnabled(true);
      !speechRecognizerRef.current && setDisplayText('Escuchando...');
      speechRecognizerRef.current = speechRecognizer;
    } else {
      speechRecognizerRef.current = speechRecognizer;
      setTimeout(() => {
        console.log('Se inicia el micro en pausa tras reconectar');
        // damos 0,3s para que el nuevo micrófono termine de crearse
        pauseMic();
      }, 300);
    }
    isReconnecting = false;
    console.log('Micrófono activado.');
  };
  const onStartContinuousRecognitionError = (err: string) =>
    console.log('onStartRecognitionError', err);

  const startContinuousRecognition = (
    speechRecognizer: speechsdk.SpeechRecognizer
  ) => {
    speechRecognizer.recognized = (s, e) => {
      // console.log(`ESCUCHANDO...${isMicOpened} ${e.result.text}`);
      if (
        e.result.reason === speechsdk.ResultReason.RecognizedSpeech &&
        isMicOpened
      ) {
        changeVisualMode('close-mic');
        talk(e.result.text);
      }
    };
    speechRecognizer.canceled = (s, e) => {
      console.log(
        `Reconocimiento de voz cancelado: ErrorCode=${e.errorCode} Message=${e.errorDetails}`
      );
      if (e.reason === speechsdk.CancellationReason.Error) {
        changeVisualMode('reset');
      }
    };
    speechRecognizer.sessionStopped = (s, e) => {
      // estados a cambiar cuando el micro se pausa
      isMicOpened = false;
      setIsMicEnabled(false);
      console.log('Micrófono pausado.');
    };
    initializeOrResumeMic(speechRecognizer);
  };

  /*
  const startOnceRecognition = (
    speechRecognizer: speechsdk.SpeechRecognizer
  ) => {
    // TODO: hay que adaptar este modo de escucha
    const onStartRecognition = (result: speechsdk.SpeechRecognitionResult) => {
      console.log('result', result, result.reason, result.text);
      if (result.reason === speechsdk.ResultReason.RecognizedSpeech) {
        // changeVisualMode('close-mic'); // adaptarlo a reconocimiento de voz no contínuo (por botón)
        addNewMessage(result.text, 'user', userName);
        talk(result.text);
      } else if (result.reason === speechsdk.ResultReason.Canceled) {
        changeVisualMode('reset');
        console.log(
          `"CANCELLED: ErrorCode=${result.reason} Message=${result.errorDetails}`
        );
      } else if (result.reason === speechsdk.ResultReason.NoMatch) {
        changeVisualMode('reset');
        setDisplayText('No se ha detectado la voz. Pulsa de nuevo para hablar');
      }
    };
    const onStartRecognitionError = (err: string) => {
      console.log('onStartRecognitionError', err);
      changeVisualMode('reset');
    };
    speechRecognizer.recognizeOnceAsync(
      onStartRecognition,
      onStartRecognitionError
    );
  }; */

 const enableMic = async () => {
    console.log('Creando el micrófono...');
  
    !isReconnecting && setDisplayText('Activando micrófono...');
  
    try {
      // Solicitar permisos explícitamente antes de configurar el micrófono, si es Safari
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      
      if (isSafari) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          stream.getTracks().forEach(track => track.stop()); // Detener el stream después de obtener el permiso
        } catch (error) {
          console.error('Error al obtener acceso al micrófono en Safari:', error);
          showToast('No se pudo acceder al micrófono. Asegúrate de dar permisos.', 'error');
          return;
        }
      }
  
      const tokenObj = await getAzureVoiceToken();
      const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
        tokenObj.authToken,
        tokenObj.region
      );
      speechConfig.speechRecognitionLanguage =
        initialConfig?.speechRecognitionLanguage ?? 'es-ES';
      
      const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
      
      const speechRecognizer = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
  
      startContinuousRecognition(speechRecognizer);
    } catch (error) {
      console.error('Error al habilitar el micrófono:', error);
      showToast('Error al habilitar el micrófono.', 'error');
    }
  };
  

  const sttFromMic = async (isManualClick: boolean = false) => {
    // Verifica si el navegador es Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  
    if (isSafari && !isMicEnabled) {
      try {
        // Solicitar permisos explícitamente en Safari
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        stream.getTracks().forEach(track => track.stop()); // Detiene el stream después de obtener el permiso
      } catch (error) {
        console.error('Error al obtener acceso al micrófono en Safari:', error);
        showToast('No se pudo acceder al micrófono. Asegúrate de dar permisos.', 'error');
        return;
      }
    }
  
    const isStreamingConnected = streamingState === 'connected';
    let isStreamingInitialized = isStreamingConnected;
    if (streamingMode && !isStreamingConnected) {
      !isReconnecting && setDisplayText('Conectando con el servicio de streaming...');
      isStreamingInitialized = await connect(0);
      !isStreamingInitialized && changeVisualMode('failed');
    }
    

    if (
      (isStreamingInitialized && isStreamingConnected) ||
      voiceOnlyMode ||
      voiceSpeechMode
    ) {
      if (isMicEnabled === false) {
        // abre el micro de forma manual
        isManualClick ? destroyMic(true) : changeVisualMode('open-mic');
      } else if (isMicEnabled) {
        // cierra el micro de forma manual
        isManualClick ? destroyMic(true) : changeVisualMode('close-mic');
      } else {
        // habilita el micro cuando vuelves a pulsar, si no se habilita desde el useEffect
        enableMic();
      }
    }
  };
  

  const onIceGatheringStateChange = (event: Event) => {
    // console.log('1', peerConnectionRef.current[indexConnection].connection?.iceGatheringState);
    // value "gathering" when d-id "/ice" is responding
    // value "complete" when d-id "/ice" has finished
  };

  async function onIceCandidate(event: RTCPeerConnectionIceEvent) {
    if (event.candidate) {
      const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

      if (!loadingIce) {
        try {
          loadingIce = true;
          await postOnIceCandidate(
            streamIdRef.current,
            sessionIdRef.current,
            candidate,
            sdpMid,
            sdpMLineIndex
          );
          loadingIce = false;
        } catch (error) {
          loadingIce = false;
          console.error('onIceCandidate catch', error);
          changeVisualMode('reset');
        }
      }
    }
  }

  const onIceConnectionStateChange = (event: Event) => {
    if (
      peerConnectionRef.current[indexConnection].connection
        ?.iceConnectionState === 'failed' ||
      peerConnectionRef.current[indexConnection].connection
        ?.iceConnectionState === 'closed'
    ) {
      console.log(
        'onIceConnectionStateChange (se cierra peerConnection):',
        peerConnectionRef.current[indexConnection].connection
          ?.iceConnectionState
      );
      stopAllStreams();
      closePC();
    }
  };

  const onConnectionStateChange = (event: Event) => {
    if (!peerConnectionRef.current[indexConnection].connection) return;
    console.log(
      `Connection State Change: ${peerConnectionRef.current[indexConnection].connection?.connectionState}`
    );
    setStreamingState((prevStreamingState) => {
      setPrevStreamingState(prevStreamingState);
      return peerConnectionRef.current[indexConnection].connection
        ?.connectionState;
    });
  };

  const onSignalingStateChange = (event: Event) => {
    if (!peerConnectionRef.current[indexConnection].connection) return;
    console.log(
      `Signaling State Change: ${peerConnectionRef.current[indexConnection].connection?.signalingState}`
    );
  };

  const onTrack = (event: RTCTrackEvent) => {
    if (!peerConnectionRef.current[indexConnection].connection) return;

    try {
      // Asegurarse de que el video aún no tiene un stream asignado
      if (talkVideoRef.current?.srcObject) return;

      // Asignar el stream al video
      if (talkVideoRef.current) {
        talkVideoRef.current.srcObject = event.streams[0];
      }
    } catch (e) {
      console.error('Error durante onTrack:', e);
    }
  };

  const stopAllStreams = async () => {
    if (talkVideoRef.current?.srcObject) {
      console.log('stopping video streams');
      (talkVideoRef.current.srcObject as MediaStream)
        .getTracks()
        .forEach((track) => track.stop());
      talkVideoRef.current.srcObject = null;
    }
  };

  const closePC = () => {
    // console.log("peerConnectionRef.current:", peerConnectionRef.current.signalingState);
    console.log('closePC invoked');
    const peerConnection = peerConnectionRef.current[indexConnection];
    if (peerConnection.connection) {
      console.log('stopping peer connection');
      peerConnection.connection.close();
      peerConnection.connection.removeEventListener(
        'icegatheringstatechange',
        onIceGatheringStateChange
      );
      peerConnection.connection.removeEventListener(
        'icecandidate',
        onIceCandidate
      );
      peerConnection.connection.removeEventListener(
        'iceconnectionstatechange',
        onIceConnectionStateChange
      );
      peerConnection.connection.removeEventListener(
        'connectionstatechange',
        onConnectionStateChange
      );
      peerConnection.connection.removeEventListener(
        'signalingstatechange',
        onSignalingStateChange
      );
      peerConnection.connection.removeEventListener('track', onTrack);
    }
    peerConnection.connection = null;
    peerConnection.active = false;
  };

  const createPeerConnection = async (
    offer: RTCSessionDescriptionInit,
    iceServers: RTCIceServer[],
    indexConnection: number
  ) => {
    try {
      if (!peerConnectionRef.current[indexConnection].connection) {
        console.log('Creando la conexión RTCPeerConnection...');
        peerConnectionRef.current[indexConnection].connection =
          new RTCPeerConnection({ iceServers });
        peerConnectionRef.current[indexConnection].active = true;
        const newPeerConnection =
          peerConnectionRef.current[indexConnection].connection;
        if (!newPeerConnection) {
          console.log('Algo está mal con la inicialización de la conexión');
          return;
        }
        newPeerConnection.addEventListener(
          'icegatheringstatechange',
          onIceGatheringStateChange,
          true
        );
        newPeerConnection.addEventListener(
          'icecandidate',
          onIceCandidate,
          true
        );
        newPeerConnection.addEventListener(
          'iceconnectionstatechange',
          onIceConnectionStateChange,
          true
        );
        newPeerConnection.addEventListener(
          'connectionstatechange',
          onConnectionStateChange,
          true
        );
        newPeerConnection.addEventListener(
          'signalingstatechange',
          onSignalingStateChange,
          true
        );
        newPeerConnection.addEventListener('track', onTrack, true);
        await newPeerConnection.setRemoteDescription(offer);
        const answer: RTCSessionDescriptionInit =
          await newPeerConnection.createAnswer();
        await newPeerConnection.setLocalDescription(answer);
        return answer;
      }
    } catch (e) {
      console.error('Error during createPeerConnection', e);
    }
  };

  const connect = async (indexConnection: number): Promise<boolean> => {
    console.log('Connect function invoked');
    if (
      peerConnectionRef.current[indexConnection].connection?.connectionState ===
      'connected'
    ) {
      console.log('Already connected, existing function');
      return true;
    }
    stopAllStreams();
    closePC();
    try {
      console.log('Inicializando una sesión de streaming...');
      const sessionData = await postCreateSession(initialConfig?.avatarImgUrl);
      if (sessionData) {
        const {
          id: newStreamId,
          offer,
          ice_servers: iceServers,
          session_id: newSessionId,
        } = sessionData;
        streamIdRef.current = newStreamId;
        sessionIdRef.current = newSessionId;
        const sessionClientAnswer: RTCSessionDescriptionInit | undefined =
          await createPeerConnection(offer, iceServers, indexConnection);
        if (sessionClientAnswer) {
          console.log('Mandar respuesta a D-ID');
          await postSendAnswer(
            streamIdRef.current,
            sessionIdRef.current,
            sessionClientAnswer
          );
        }
        return true;
      } else {
        console.log('No se pudo crear una sesión de streaming.');
        return false;
      }
    } catch (e) {
      console.error('Error during connection:', e);
      return false;
    }
    
  };

  const getSynthesizer = async (enableVolume: boolean) => {
    const tokenObj = await getAzureVoiceToken();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
      tokenObj.authToken,
      tokenObj.region
    );
    speechConfig.speechRecognitionLanguage =
      initialConfig?.speechRecognitionLanguage ?? 'es-ES';
    speechConfig.speechSynthesisLanguage =
      initialConfig?.speechRecognitionLanguage ?? 'es-ES';
    const player = new speechsdk.SpeakerAudioDestination();
    player.onAudioStart = function (s) {
      console.log('Comienza el speech');
      if (!enableVolume) {
        player.volume = 0;
        player.mute();
      }
    };
    player.onAudioEnd = function (s) {
      console.log('Termina el speech');
      changeVisualMode('finish');
    };
    const synthesizer = new speechsdk.SpeechSynthesizer(
      speechConfig,
      speechsdk.AudioConfig.fromSpeakerOutput(player)
    );
    return synthesizer;
  };

  const initializeSpeech = async ({
    speakText,
    timeoutTime,
    volume,
  }: AzureTalk) => {
    const synthesizer = await getSynthesizer(volume);
    const cb = (e: speechsdk.SpeechSynthesisResult) => {
      synthesizer.close();
    };
    const err = (e: string) => {
      synthesizer.close();
      console.log('synthesizer err');
    };
    setTimeout(() => {
      synthesizer.speakTextAsync(speakText, cb, err);
    }, timeoutTime);
  };

  const talk = async (
    userMessage: string,
    initialGreeting: boolean = false
  ) => {
    window.focus();

    if (isWaitingResponse) {
      showToast('Se está procesando una respuesta, espera un momento.', 'warn');
      return;
    }
  
    if (
      streamingMode &&
      (peerConnectionRef.current[indexConnection]?.connection?.signalingState !== 'stable' ||
        peerConnectionRef.current[indexConnection]?.connection?.iceConnectionState !== 'connected')
    ) {
      showToast('La conexión con el streaming no es estable.', 'warn');
      console.log('Talk - la conexión no es estable o no está conectada');
      return;
    }
  
    try {
      // Agregar el mensaje del usuario al chat si no es un saludo inicial
      if (!initialGreeting) {
        addNewMessage(toMessageFormat(userMessage), 'user', userName);
      }
  
      // Indicar que se está esperando la respuesta del bot
      isWaitingResponse = true;
      setIsBotThinking(true);
      console.log('Recibiendo una respuesta del Avatar...');
  
      let gptResponse: AvatarResponse | undefined;
  
      // Verificamos si el avatar es de tipo GPT
      if (avatarType?.toLowerCase() === 'gptceu') {
        // Obtenemos la respuesta de GPT
        gptResponse = await getGptData(userMessage, userToken, user);
      } else {
        // Obtenemos la respuesta de getVariableData y la transformamos a AvatarResponse
        const variableDataResponse = await getVariableData(userMessage, streamIdRef.current);
        gptResponse = toMessageFormat(variableDataResponse);
      }
  
      // Asegurarse de que gptResponse esté correctamente formateado
      gptResponse = toMessageFormat(gptResponse || '');
  
      // Crear un objeto para manejar la síntesis de voz si es necesario
      const speakTalk: AzureTalk = {
        speakText: gptResponse.respuesta_corta ?? gptResponse.respuesta_usuario,
        volume: VOLUMEN_ON,
        timeoutTime: 0,
      };
  
      // Finalizar la espera de la respuesta del bot
      isWaitingResponse = false;
      setIsBotThinking(false);
  
      // Agregar el mensaje de respuesta del bot al chat
      addNewMessage(gptResponse, 'bot', initialConfig?.avatarName);
  
      // Lógica para el modo de streaming
      if (streamingMode) {
        console.log('Intentando inicializar el streaming...');
        try {
          // Inicializa el streaming con el texto del bot
          await postInitStreaming(
            streamIdRef.current,
            sessionIdRef.current,
            initialConfig?.azureVoiceId,
            speakTalk.speakText
          );
  
          // Desactivar el volumen para evitar hablar dos veces
          speakTalk.volume = VOLUMEN_OFF;
          speakTalk.timeoutTime = 1000;
  
          // Inicia el discurso en segundo plano si es necesario
          if (speakTalk.speakText) {
            initializeSpeech(speakTalk);
          }
  
          console.log('Talk completed');
        } catch (error) {
          console.error('Fallo con el streaming:', error);
        }
      } else {
        // Lógica para modo sin streaming (solo voz o chat)
        setIsChatSent(false);
  
        // Si el modo es de voz y hay texto, utilizar la síntesis de voz
        if (voiceSpeechMode && speakTalk.speakText) {
          initializeSpeech(speakTalk);
        } else {
          // Finaliza la interacción visual
          changeVisualMode('finish');
        }
        

      }
     
      // Reactivar el micrófono después de que el avatar haya terminado de hablar
      if (voiceOnlyMode || voiceSpeechMode ) {
        setTimeout(() => {
          enableMic();  // Reactiva el micrófono para la siguiente interacción
        }, 1000);  // Asegúrate de tener una ligera pausa antes de reactivar
      }
  
    } catch (e) {
      console.error('Fallo en Talk:', e);
      isWaitingResponse = false;
      setIsBotThinking(false);
      showToast('Error en la conversación. Inténtalo de nuevo.', 'error');
    }
   
  };
  
  

  // #############################
  // Gestión del render del video y streaming

  const handleVideoEnd = () => {
    // Cambiar a tu streaming en vivo aquí
    setShowStreaming(true);
    // Iniciar tu streaming y asignar el stream al elemento de video
    // Por ejemplo: talkVideoRef.current.srcObject = tuStream;
  };

  const addNewMessage = async (
    response: AvatarResponse,
    senderType: string,
    senderName: string
  ) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { response, sender: senderType, author: senderName },
    ]);
    setDisplayText(`${senderName}: ${response.respuesta_usuario}`);
  };

  const setNewMessage = (
    prevMessage: AvatarResponse,
    newMessage: AvatarResponse
  ): AvatarResponse => {
    return {
      ...prevMessage,
      ...newMessage,
    };
  };

  const editMessageById = (id: string, newMessage: AvatarResponse | any) => {
    const messageToEdit = messages.find(
      (message) => message.response.id === id
    );
    if (messageToEdit) {
      messageToEdit.response = setNewMessage(
        messageToEdit.response,
        newMessage
      );
      setMessages(messages);
    }
  };

  const deleteMessageById = (id: string) => {
    const index = messages.findIndex((message) => message.response.id === id);
    messages.splice(index, 1);
    setMessages(structuredClone(messages));
  };

  useEffect(() => {
    switch (streamingState) {
      case 'connected':
        enableMic();
        break;
      case 'failed':
        if (prevStreamingState !== 'disconnected') {
          changeVisualMode('failed');
        }
        break;
      case 'disconnected':
        // changeVisualMode('reset'); // volver al estado inicial "Pulsa para hablar"
        // volver a conectar con el streaming
        console.log('---CONECTANDO DE NUEVO---');
        isReconnecting = true;
        destroyMic();
        sttFromMic();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamingState, prevStreamingState]);

  useEffect(() => {
    (async () => {
      if (isChatSent && chatInput && isMicEnabled === undefined) {
        await talk(chatInput);
      }
    })();
    if (!isChatSent) {
      isMicEnabled === undefined &&
        document.getElementById('chatInput')?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatSent, chatInput]);

  const onKeyDownChatInput = async (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter') {
      setIsChatSent(true);
    }
  };

  const onChangeChatInput = (e: React.FormEvent<HTMLInputElement>) =>
    setChatInput(e.currentTarget.value);

  const onClickChatInput = (e: React.MouseEvent<HTMLInputElement>) =>
    isMicEnabled !== undefined && destroyMic(true);

  useEffect(() => {
    if (streamingMode) {
      const talkingBtn = document.getElementById('talking-btn');
      const micControlText = document.getElementById('mic-control-btn');
      if (isMicEnabled === undefined) {
        talkingBtn.style.backgroundColor = 'rgba(50, 107, 240, 0.7)';
        micControlText.textContent = 'Iniciar streaming y micro';
      } else if (isMicEnabled) {
        talkingBtn.style.backgroundColor = '#25a725';
        micControlText.textContent = 'Deshabilitar micrófono';
      } else {
        talkingBtn.style.backgroundColor = '#e22121';
        micControlText.textContent = 'Habilitar micrófono';
      }
    }
    if (voiceOnlyMode || voiceSpeechMode) {
      if (isMicEnabled === undefined) {
        // mic no creado
        setMicImg(micNormalImg);
      } else if (isMicEnabled) {
        // mic creado
        setMicImg(micListeningImg);
      } else {
        // mic pausado
        setMicImg(micForbiddenImg);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMicEnabled]);


  const uploadFilePartial = (file: File, description: string) =>
    uploadFile(file, user?.email, description);

  const context: AvatarContextModel = {
    addNewMessage,
    editMessageById,
    deleteMessageById,
    uploadFilePartial,
    userName,
  };

  return (
    <AvatarContext.Provider value={context}>
      <NoData errorOnInitialConfig={errorOnInitialConfig}>
        <div className="avatar-component">
          {streamingMode && (
            <div className="subarea subarea-img">
              <div className="avatar-container">
                {showStreaming ? (
                  <>
                    <video
                      ref={talkVideoRef}
                      autoPlay
                      playsInline
                      poster={initialConfig?.avatarImgUrl}
                      
                    />
                    <button
                      id="talking-btn"
                      className="subarea-img-button"
                      onClick={() => sttFromMic()}
                    >
                      {displayText}
                    </button>
                  </>
                ) : (
                  <ReactPlayer
                    url={initialConfig?.videoInicial}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                    
                    onEnded={handleVideoEnd}
                    config={{
                      vimeo: {
                        playerOptions: {
                          byline: false,
                          portrait: false,
                          title: false,
                          color: 'ffffff',
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {initialConfig && (
            <div
              className={`subarea ${
                !streamingMode ? 'subarea-only-text' : 'subarea-text'
              }`}
            >
              <div className="chat-container light-shadow column">
                <Chat messages={messages} isBotThinking={isBotThinking} />
                <div className="chat-actions row">
                  {!streamingMode && (
                    <input
                      type="text"
                      id="chatInput"
                      name="chatInput"
                      className="chat-input"
                      value={chatInput}
                      onChange={onChangeChatInput}
                      onKeyDown={onKeyDownChatInput}
                      onClick={onClickChatInput}
                      disabled={isChatSent}
                      autoComplete="off"
                      placeholder="Mensaje para tu asistente"
                    />
                  )}
                  {(voiceOnlyMode || voiceSpeechMode) && (
                    <button
                      className="chat-mic-btn"
                      onClick={() => sttFromMic(true)}
                    >
                      <img src={micImg} alt="Mic" />
                    </button>
                  )}
                  {streamingMode && (
                    <button
                      id="mic-control-btn"
                      className="subarea-img-button"
                      onClick={() => sttFromMic()}
                    >
                      Habilitar micrófono
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
          <LogoutBtn userToken={userToken} logout={logout} />
        </div>
      </NoData>
    </AvatarContext.Provider>
  );
};

export default AvatarApp;
