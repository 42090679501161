import { DidWebRtcResponse } from '../../models/d-id';
import endpoints from './endpoints.json';

const postOnIceCandidate = async (
  streamId: string,
  session_id: string,
  candidate: string,
  sdpMid: string,
  sdpMLineIndex: number
) => {
  await fetch(
    `${process.env.REACT_APP_DID_API}${endpoints.post.didTalksStreams}/${streamId}${endpoints.post.didTalksStreamsIce}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Basic ${process.env.REACT_APP_DID_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        candidate,
        sdpMid,
        sdpMLineIndex,
        session_id,
      }),
    }
  );
};

const postCreateSession = async (
  avatarImgUrl: string
): Promise<DidWebRtcResponse | undefined> => {
  const sessionResponse = await fetch(
    `${process.env.REACT_APP_DID_API}${endpoints.post.didTalksStreams}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Basic ${process.env.REACT_APP_DID_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        source_url: avatarImgUrl,
      }),
    }
  );
  const responseData: DidWebRtcResponse = await sessionResponse.json();
  if (sessionResponse?.status === 201 && responseData) {
    return responseData;
  }
};

const postSendAnswer = async (
  streamId: string,
  sessionId: string,
  sessionClientAnswer: RTCSessionDescriptionInit
) => {
  await fetch(
    `${process.env.REACT_APP_DID_API}${endpoints.post.didTalksStreams}/${streamId}${endpoints.post.didTalksStreamsSdp}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Basic ${process.env.REACT_APP_DID_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        answer: sessionClientAnswer,
        session_id: sessionId,
      }),
    }
  );
};

const postInitStreaming = async (
  streamId: string,
  sessionId: string,
  azureVoiceId: string,
  respuesta_usuario: string
) => {
  const streamingResponse = await fetch(
    `${process.env.REACT_APP_DID_API}${endpoints.post.didTalksStreams}/${streamId}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Basic ${process.env.REACT_APP_DID_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        script: {
          type: 'text',
          subtitles: 'false',
          provider: {
            type: 'microsoft',
            voice_id: azureVoiceId,
          },
          ssml: 'false',
          input: respuesta_usuario,
        },
        driver_url: 'bank://lively/',
        config: {
          stitch: true,
        },
        session_id: sessionId,
      }),
    }
  );
  console.log('Resultado del streaming:', streamingResponse.status === 200);
};

export {
  postOnIceCandidate,
  postCreateSession,
  postSendAnswer,
  postInitStreaming,
};
