import { FileIconProps } from './props';
import { getFileDataByType } from '../../../../../helpers/file';

const FileIcon = ({ fileName, fileType }: FileIconProps) => {
  const fileData = getFileDataByType(fileName, fileType);

  return (
    <div className="file-container row-v-center light-shadow">
      {<img src={fileData.icon} alt="file" className="file-icon" />}
      {fileData.name}
    </div>
  );
};

export default FileIcon;
