import { getFullDate } from '../helpers/date';
import { AvatarResponse } from './avatar';

export interface Message {
  response: AvatarResponse;
  sender: string;
  author: string;
}

export function isHtmlMessage(message: string): boolean {
  return (
    message.startsWith('<div>', 0) && message.endsWith('</div>', message.length)
  );
}

export function toBasicFormat(data: any = {}): AvatarResponse {
  const timeId = `${new Date().getTime()}`;
  return {
    id_proceso: '0',
    respuesta_usuario: '',
    formato_respuesta_usuario: 'PlainText',
    archivo_upload: 'no',
    thread_id: '',
    asignatura_id: '',
    url_img: '',
    file: undefined,
    id: timeId,
    idMessageLinked: '',
    time: getFullDate(),
    ...data,
  };
}

export function toMessageFormat(
  message: AvatarResponse | string | undefined
): AvatarResponse {
  const basicResponse: AvatarResponse = toBasicFormat();
  if ((message as AvatarResponse)?.respuesta_usuario) {
    const data = message as AvatarResponse;
    if (isHtmlMessage(data.respuesta_usuario)) {
      data.formato_respuesta_usuario = 'HTML';
    }
    return {
      ...basicResponse,
      ...data,
    };
  }
  return {
    ...basicResponse,
    respuesta_usuario: (message as string) ?? '',
  };
}
