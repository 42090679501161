import AvatarApp from './ui/screens/AvatarApp';
import Formulario from './ui/screens/Formulario';

import Toast from './ui/components/Toast';
import React from 'react';

const App = () => {
  return (
    <>
      <Toast>
        <AvatarApp />
        
      </Toast>
      <Formulario />

    </>

  );
};

export default App;
