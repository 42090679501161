import React, { useState } from 'react';
import '../../css/formulario.css';

const Formulario: React.FC = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    apellidos: '',
    email: '',
    telefono: '',
    servicio: '', // Campo para el servicio interesado
    mensaje: '' // Nuevo campo para el mensaje
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const functionUrl = ''; // Aquí se establecerá la URL de la función de Azure cuando la tengas.

    if (!functionUrl) {
      console.error("La URL de la función de Azure no está definida.");
      return;
    }

    try {
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        console.log('Datos enviados correctamente');
        // Aquí puedes manejar el caso de éxito, por ejemplo, mostrar un mensaje de agradecimiento
      } else {
        console.error('Error al enviar los datos');
        // Aquí puedes manejar el error, por ejemplo, mostrar un mensaje de error al usuario
      }
    } catch (error) {
      console.error('Error al enviar los datos:', error);
      // Aquí puedes manejar el error de red, por ejemplo, mostrar un mensaje de error al usuario
    }
  };

  return (
    <form className="formulario" onSubmit={handleSubmit}>
      <h2>Contacta con Nosotros</h2>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="apellidos">Apellidos</label>
          <input
            type="text"
            id="apellidos"
            name="apellidos"
            value={formData.apellidos}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="nombre">Nombre</label>
          <input
            type="text"
            id="nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="telefono">Teléfono</label>
          <input
            type="tel"
            id="telefono"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="servicio">Servicio interesado</label>
        <select
          id="servicio"
          name="servicio"
          value={formData.servicio}
          onChange={handleChange}
          required
        >
          <option value="">Seleccione un servicio</option>
          <option value="reserva_online_restaurantes">Reserva online restaurantes</option>
          <option value="gestion_citas">Gestión de citas</option>
          <option value="venta_productos_supermercados">Venta productos supermercados</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="mensaje">Mensaje</label>
        <textarea
          id="mensaje"
          name="mensaje"
          value={formData.mensaje}
          onChange={handleChange}
          rows={4}
        />
      </div>
      <button type="submit" className="submit-btn">Enviar</button>
    </form>
  );
};

export default Formulario;

