import { SPEECH_REGION, SPEECH_REGION_REPLACE } from '../../constants';
import { AzureVoice } from '../../models/azure';
import endpoints from './endpoints.json';

const getAzureVoiceToken = async (): Promise<AzureVoice> => {
  const response = await fetch(
    `${process.env.REACT_APP_AZURE_SPEECH_API?.replace(
      SPEECH_REGION_REPLACE,
      SPEECH_REGION
    )}${endpoints.post.azureSpeech}`,
    {
      method: 'POST',
      headers: {
        'Ocp-Apim-Subscription-Key': process.env
          .REACT_APP_AZURE_SPEECH_KEY as string,
      },
    }
  );

  const authToken = await response.text();
  return { authToken, region: SPEECH_REGION };
};

export { getAzureVoiceToken };
