import { LoadingChatProps } from './props';

const LoadingChat = ({ isBotThinking }: LoadingChatProps) => {
  return (
    isBotThinking && (
      <div className="bot-thinking">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    )
  );
};

export default LoadingChat;
