import { UploadFileCancelBtnProps } from './props';
import { useAvatarContext } from '../../../../../context/Avatar/AvatarContext';
import { useChatContext } from '../../../../../context/Chat/Chat';

const UploadFileCancelBtn = ({ message }: UploadFileCancelBtnProps) => {
  const { editMessageById, deleteMessageById } = useAvatarContext();
  const { setFileDescription } = useChatContext();

  const onClick = () => {
    setFileDescription('');
    // edit input file message
    editMessageById(message.idMessageLinked, {
      archivo_upload: 'yes',
    });
    // remove message about file selected
    deleteMessageById(message.id);
  };

  return (
    <button className="file-btn file-cancel-btn light-shadow" onClick={onClick}>
      Cancelar
    </button>
  );
};

export default UploadFileCancelBtn;
