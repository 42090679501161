import '../../../css/toast.css';
import { ToastProps } from './props';

const Toast = ({ children }: ToastProps) => {
  return (
    <>
      {children}
      <div id="toast-container" className="toast-container"></div>
    </>
  );
};

export default Toast;
