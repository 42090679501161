import { useChatContext } from '../../../../../context/Chat/Chat';

const FileDescriptionInput = () => {
  const { fileDescription, setFileDescription } = useChatContext();
  const onChangeChatInput = (e: React.FormEvent<HTMLInputElement>) =>
    setFileDescription(e.currentTarget.value);

  return (
    <input
      type="text"
      id="fileDescription"
      name="fileDescription"
      className="chat-input"
      onChange={onChangeChatInput}
      value={fileDescription}
      autoComplete="off"
      placeholder="Descripción"
    />
  );
};

export default FileDescriptionInput;
