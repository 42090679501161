export function showToast(message: string, type = null, delay = 4000) {
  const toasts = document.getElementById('toast-container');
  const notif = document.createElement('div');

  notif.classList.add('toast-message');
  if (type === 'info') notif.classList.add('info');
  else if (type === 'success') notif.classList.add('success');
  else if (type === 'warn') notif.classList.add('warn');
  else if (type === 'error') notif.classList.add('error');

  notif.innerText = message;
  toasts.append(notif);

  setTimeout(() => {
    notif.remove();
  }, delay);
}
