import { UploadFileInputProps } from './props';
import attachFile from '../../../../../assets/images/attach.png';
import { showToast } from '../../../../../helpers/toast';
import { useAvatarContext } from '../../../../../context/Avatar/AvatarContext';
import { toBasicFormat } from '../../../../../models/chat';
import { AvatarResponse } from '../../../../../models/avatar';
import { getFileSizeMB } from '../../../../../helpers/file';

const UploadFile = ({ idMessage }: UploadFileInputProps) => {
  const { addNewMessage, editMessageById, userName } = useAvatarContext();

  const onChange = async (e: React.FormEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files?.length > 0) {
      const selectedFile = files[0];
      const size = getFileSizeMB(selectedFile.size);
      if (size <= 25) {
        if (selectedFile.type === 'application/pdf') {
          const gptResponse: AvatarResponse = toBasicFormat({
            respuesta_usuario: 'Añade una descripción del archivo.',
            formato_respuesta_usuario: 'FileToShow',
            file: selectedFile,
            idMessageLinked: idMessage,
          });
          editMessageById(idMessage, {
            archivo_upload: 'no',
          });
          addNewMessage(gptResponse, 'user', userName);
          e.currentTarget.value = ''; // remove selected files from input
        } else {
          showToast('Solo se aceptan ficheros PDF.', 'warn');
        }
      } else {
        showToast('Tamaño del fichero superado. Máximo 25MB.', 'warn');
      }
    }
  };

  return (
    <>
      <input
        type="file"
        id="uploadFileInput"
        accept="application/pdf"
        className="invisible"
        onChange={onChange}
      />
      <div className="upload-file-container row">
        <label
          htmlFor="uploadFileInput"
          className="upload-file-label light-shadow"
        >
          Adjuntar fichero
          <img src={attachFile} alt="addFile" className="upload-file-img" />
        </label>
      </div>
    </>
  );
};

export default UploadFile;
