import logoutImg from '../../../assets/images/logout.png';
import { LogoutProps } from './props';

const LogoutBtn = ({ userToken, logout }: LogoutProps) => {
  return (
    userToken && (
      <button className="logout-btn" onClick={logout}>
        <img src={logoutImg} alt="logout" />
      </button>
    )
  );
};

export default LogoutBtn;
