const formatDateValue = (input: number) => (input > 9 ? input : `0${input}`);

const getDay = (date: Date) => date.getDate();
const getMonth = (date: Date) => date.getMonth() + 1;
const getYear = (date: Date) => date.getFullYear().toString().substring(2, 4);
const getHours = (date: Date) => date.getHours();
const getMinutes = (date: Date) => date.getMinutes();
const getSeconds = (date: Date) => date.getSeconds();

export function getFullDate(): string {
  const date = new Date();

  const dateFormatted = `${formatDateValue(getDay(date))}/${formatDateValue(
    getMonth(date)
  )}/${getYear(date)} ${formatDateValue(getHours(date))}:${formatDateValue(
    getMinutes(date)
  )}:${formatDateValue(getSeconds(date))}`;
  return dateFormatted;
}
