import { addEllipsis, shortenLength } from './string';
import { FILE_NAME_MAX_LENGTH } from '../constants';
import pdfIcon from '../assets/images/pdf-icon.png';
import fileIcon from '../assets/images/file-icon.png';

function getFileDataByType(name: string, type: string) {
  const nameSplitted = name?.split('.');
  const extension = nameSplitted.pop();
  let nameFormatted = name;
  if (name?.length > FILE_NAME_MAX_LENGTH) {
    nameFormatted = `${addEllipsis(
      shortenLength(nameSplitted.join('.'), FILE_NAME_MAX_LENGTH)
    )}${extension}`;
  }
  switch (type) {
    case 'application/pdf':
      return { name: nameFormatted, icon: pdfIcon };
    default:
      return { name: nameFormatted, icon: fileIcon };
  }
}

function getFileSizeMB(size: number): number {
  return size / 1024 ** 2; // convertion to MB
}

export { getFileDataByType, getFileSizeMB };
